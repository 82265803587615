import React, { useState } from "react";
import Header from "../components/home/Header";
import Footers from "../components/home/Footers";
import { contactUs, introBg } from "../assets";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";



export default function Contact(){
    const [formData, setFormData] = useState({
        firstName: '', lastName: '', company: '', phone: '', businessEmail: '', message: '', termsAccepted: false
    });

    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch('/forms/submit-consultation/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        })
        .then(res => res.json())
        .then(data => setMessage(data.success || data.error))
        .catch(error => setMessage('Error submitting form'));
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
    };

    const handleButtonClick = () => {
        const calendlyUrl = 'https://calendly.com/mainlogixlimited/schedule-a-call-with-us-at-mainlogix-technology-canada';
        window.Calendly.initPopupWidget({ url: calendlyUrl });
        return false;
      };


    return (
        <div className="bg-slate-100 absolute" >
            <Helmet>
                <title>Contact Us | Mainlogix </title>
                <meta name="description" content="Get in touch with Mainlogix for expert IoT and automation consultation. Our team provides personalized support and innovative solutions to address your technology needs. Contact us today for tailored advice, professional guidance, and to start transforming your business with cutting-edge IoT solutions." />
            </Helmet>

            <Header />

            <div className="relative w-full ">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${introBg})` }}
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75"></div>
                </div>
                
                <div className="page-title smd:grid-cols-1 grid grid-cols-2 w-full bg-opacity-75 py-10 px-[5%] relative justify-center smd:pb-44 md:pb-48 h-sm:pb-60" >
                    <div >
                        <h2 className="title-text font-poppins font-semibold text-left text-[55px] text-white">
                            Contact Us
                        </h2>
                        <p className="text-white text-left mt-4">
                            Whether you have questions, need support, or are ready to explore our cutting-edge IoT and automation solutions, we’re here to assist you. Reach out to our team for personalized consultations, expert advice, and tailored solutions that drive success.
                        </p>

                        <div className="flex justify-start my-5">
                            <button 
                                className='button-m text-center'
                                onClick={handleButtonClick}>
                                <FontAwesomeIcon icon={faPhone} className="pr-2" />
                                    Book A Call With Us!
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            
            <div className="relative -top-40 bg-transparent">
                <div className=" grid smd:grid-cols-1 grid-cols-2 gap-6 mx-[5%] p-10 pt-[-10%] z-[1000] bg-white rounded-2xl">  
                    <div className="z-[1000]">
                        <form onSubmit={handleSubmit}>
                        <div className="grid smd:grid-cols-1 grid-cols-2 gap-4">
                            <div>
                            <label htmlFor="firstName" className="block font-poppins text-[16px] 2xl:text-2xl font-semibold mb-2">First name</label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                placeholder="First name here"
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                                required
                            />
                            </div>
                            <div>
                            <label htmlFor="lastName" className="block font-poppins text-[16px] 2xl:text-2xl font-semibold mb-2">Last name</label>
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                placeholder="Last name here"
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                                required
                            />
                            </div>
                            <div>
                            <label htmlFor="company" className="block font-poppins text-[16px] 2xl:text-2xl font-semibold mb-2">Company</label>
                            <input
                                type="text"
                                id="company"
                                name="company"
                                value={formData.lastName}
                                onChange={handleChange}
                                placeholder="Company name here"
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                            />
                            </div>
                            <div>
                            <label htmlFor="phone" className="block font-poppins text-[16px] 2xl:text-2xl font-semibold mb-2">Phone number</label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                placeholder="34786257867889"
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                                required
                            />
                            </div>
                        </div>

                        <div className="mt-4">
                            <label htmlFor="businessEmail" className="block font-poppins text-[16px] 2xl:text-2xl font-semibold mb-2">Business email</label>
                            <input
                            type="email"
                            id="businessEmail"
                            name="businessEmail"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="Business email here"
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                            required
                            />
                        </div>

                        <div className="mt-4">
                            <label htmlFor="message" className="block font-poppins text-[16px] 2xl:text-2xl font-semibold mb-2">Message</label>
                            <textarea
                            id="message"
                            name="message"
                            value={formData.businessEmail}
                            onChange={handleChange}
                            placeholder="Leave a message"
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black resize-none"
                            rows="4"
                            required
                            ></textarea>
                        </div>

                        <div className="mt-4 flex items-start">
                            <div className="mt-1">
                            <input
                                type="checkbox"
                                id="terms"
                                name="terms"
                                value={formData.message}
                                onChange={handleChange}
                                className="w-4 h-4 text-white border border-gray-300 rounded focus:ring-black checked:bg-black"
                                required
                            />
                            </div>
                            <div className="ml-2 smd:ml-1">
                            <label 
                                htmlFor="terms" 
                                className="font-roboto font-medium ml-2 smd:ml-0 text-[14px] 2xl:text-2xl">
                                By checking this box, you consent to receive commercial 
                                messages such as newsletters, event invitations, 
                                promotional and educational content, product updates, 
                                transaction-related emails, and customer service emails, 
                                from Mainlogix in accordance with our <Link to="/privacy-policy" className="underline">
                                    Privacy Policy
                                </Link>. 
                                You may withdraw your consent at any time.
                            </label>
                            </div>
                        </div>

                        <div className="mt-6">
                            <button
                                type="submit"
                                className="px-6 py-2 2xl:py-4 w-full bg-black text-white font-semibold rounded-3xl focus:outline-none focus:ring-1 focus:ring-red-600 focus:ring-offset-10 2xl:text-2xl"
                            >
                            Submit
                            </button>
                        </div>
                        </form>
                    </div> 

                    <div className="rounded-lg" >
                        <img 
                            src={contactUs} 
                            alt="Contact and Consult" 
                            className="transform scale-x-[-1] object-cover rounded-lg smd:hidden h-[90%]"
                            
                        />
                        
                        <div className="grid smd:grid-cols-1 grid-cols-2 mt-4">
                            <div>
                                <Link to='mailto:info@mainlogix.io' >
                                    <p className="text-black">
                                        <span className="font-semibold">
                                            Email:
                                        </span> info@mainlogix.io
                                    </p>
                                </Link>
                                <Link to='tel:+2348062081641' >
                                    <p className="text-black">
                                        <span className="font-semibold">
                                            Phone no:
                                        </span> +2348062081641
                                    </p>
                                </Link>
                            </div>

                            <div>
                                <p className="text-black">
                                    <span className="font-semibold">
                                        Address:
                                    </span> Suite 1, 315 Herbert Macaulay Rd, Yaba, Lagos.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative mt-10 ">

                    <Footers />
                </div>

            </div>
            {message && <p>{message}</p>}

                
            
        </div>
    )
}


